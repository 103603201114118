import { IIdea, IProjectFollowership } from "@api/schema"
import { IProjectMemberApplication } from "@api/schema/action-requests"
import { IProjectCreationFormData } from "@components/entityTypes/project/ProjectCreationForm"
import { EntityType } from "@redux/common/reduxTypes"

/* This enum defines the usecases around the "onboarding".
 * "Onboarding" is everything related to elements that the user creates before login/registration,
 * to persist those elements after a successful login/registration.
 */
export enum OnboardingUsecases {
  /**
   * add "new idea" data to the onboarding state
   */
  AddNewIdea = "_usecase_add_onboarding_new_idea",
  /**
   * add "new project" data to the onboarding state
   */
  AddNewProject = "_usecase_add_onboarding_new_project",
  /**
   * add "new project member application" data to the onboarding state
   */
  AddNewProjectMemberApplication = "_usecase_add_onboarding_new_project_member_application",
  /**
   * add "new project followership" data to the onboarding state
   */
  AddNewProjectFollowership = "_usecase_add_onboarding_new_project_followership",

  /**
   * Replace the IOnboardingState within the AppState.
   */
  ReplaceOnboardingData = "_usecase_replace_onboarding_data",
  /**
   * reset the state after the onboarding data has been processed
   */
  ResetOnboardingData = "_usecase_reset_onboarding_data",
}

/**
 * types of existing onboarding elements
 */
export enum OnboardingType {
  None = "none",

  // NOTE: those strings are used within the translation files, so do not change them without changing there too
  NewIdea = "newIdea",
  NewProject = "newProject",
  NewProjectMemberApplication = "newProjectMemberApplication",
  NewProjectFollowership = "newProjectFollowership",
}

/**
 * Allowed object types (not only of type IModel), for which an Onboarding process exists.
 *
 * // TODO onboarding: prüfen, ob statt IProjectMemberApplication besser gleich ActionRequests unterstützt werden sollten
 */
export type OnboardingModelTypes = IProjectCreationFormData | IIdea | IProjectMemberApplication | IProjectFollowership

/**
 * Translates a given OnboardingType to a matching EntityType
 */
export const getEntityTypeFromOnboardingType = (onboardingType: OnboardingType): EntityType => {

  switch (onboardingType) {

    case OnboardingType.NewIdea:
      return EntityType.Idea

    case OnboardingType.NewProject:
      return EntityType.Project

    case OnboardingType.NewProjectMemberApplication:
      return EntityType.ActionRequest

    case OnboardingType.NewProjectFollowership:
      return EntityType.ProjectFollowership

    case OnboardingType.None:
      return null
  }
}