import { Action } from "redux"

import { IUser } from "@api/schema"
import { IFormikActions } from "@basics/form"

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

export enum RegistrationUsecases {
  RegisterUser = "REGISTER_USER",
}


/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

interface IRegistrationAction extends Action {
  type: RegistrationUsecases
}

export interface IRegisterUserAction extends IRegistrationAction {
  actions: IFormikActions
  type: RegistrationUsecases.RegisterUser
  user: IUser
}


export type RegistrationActions = IRegisterUserAction

/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */

export const registerUserAction = (user: IUser, actions: IFormikActions): IRegisterUserAction => ({
  actions,
  type: RegistrationUsecases.RegisterUser,
  user,
})