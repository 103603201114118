import { all, call, takeLatest } from "redux-saga/effects"
import { putWait, withCallback } from "redux-saga-callback"

import { iriFromIModelOrIRI } from "@api/entityTypeEndpointDefinitions"
import { IProject } from "@api/schema"
import { IProjectCreationDTO } from "@api/schema-dto"
import { ICreateExtendedProjectAction, ProjectActionTypes } from "@redux/actions/project"
import { EntityType } from "@redux/common/reduxTypes"
import { createModelAction, updateModelAction } from "@redux/common/scopedObject/actions"


export function* projectWatcherSaga(): any {
  yield all([
    takeLatest(ProjectActionTypes.CreateNewProjectAndUpdateAfterwards, withCallback(createProjectSaga)),
  ])
}

/**
 * Create a new project for an already logged in user and reloads his user object roles.
 *
 * NOTE: The saga creates first the project via createModelAction, b/c the api has a separate endpoint for creating project
 * with a specific DTO, which is not compatible with an IProject.
 * And if additional project data is given, the project will be immediately updated after the project has been successfully created.
 *
 * @param action ICreateProjectAction
 */
function* createProjectSaga(action: ICreateExtendedProjectAction) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}

  // TODO
  // NOTE this is a (early) prototype for a new style of "calling sub-sagas but avoiding that they call callbackActions".
  // REASON: we want to call onSuccess and setSubmitting when the actual ("our") saga is completed, not when any
  // sub-sagas are completed (see FCP-621 for an example, FCP-829 for another).
  // METHOD: Previously we cached the action.action callbacks and set them to null, but this seems a bit odd.
  // Instead, we call sagas with explicit callbacks passed (see call of createModelAction below).
  // Check FCP-853 for more notes on the refactoring process.

  // The backend does not accept objects when creating an Project, but needs IRIs
  const projectCreationDTO: IProjectCreationDTO = {
    ...action.projectCreationData,

    // API only accepts IRIs, not objects
    inspirationIdea: iriFromIModelOrIRI(action.projectCreationData.inspirationIdea),
    inspirationProject: iriFromIModelOrIRI(action.projectCreationData.inspirationProject),
    program: iriFromIModelOrIRI(action.projectCreationData.program),
    ratePlan: iriFromIModelOrIRI(action.projectCreationData.ratePlan),

    // DTO forces such data:
    motivation: action.projectCreationData.motivation,
    skills: action.projectCreationData.skills,
  }

  // call createModelAction without onSuccess or setSubmitting; only setErrors should be present
  const project: IProject = yield putWait(createModelAction(EntityType.Project, projectCreationDTO, { setErrors }))

  if (!project) {
    // Since we disabled setSubmitting for the sub-saga, we must call setSubmitting(false) now so that calling code may handle it,
    // i.e. reactivate the submit button in the form.
    // NOTE that we DO NOT call setSubmitting at the end of this createProjectSaga, to avoid enabling the submit button
    // before the routing to the newly created project has happened (see FCP-829)
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }

  const projectWithAdditionalData: IProject = { ...action.projectCreationData as IProject, "@id": project["@id"] }
  const updatedProject: IProject = yield putWait(updateModelAction(EntityType.Project, projectWithAdditionalData, { setErrors }))

  // call onSuccess, if defined, after necessary data is updated
  if (onSuccess) {
    yield call(onSuccess, updatedProject)
  }

  // Since we disabled setSubmitting for the sub-saga, we should call setSubmitting(false) now so that calling code may handle it,
  // i.e. reactivate the submit button in forms.
  // NOTE that we DO NOT call setSubmitting at the end of this createProjectSaga, to avoid enabling the submit button
  // before the routing to the newly created project has happened (see FCP-829)
  // if (setSubmitting) {
  //   yield call(setSubmitting, false)
  // }

  return project
}
