import { Action } from "redux"

import { IFormikActions } from "@basics/form"
import { IProjectCreationFormData } from "@components/entityTypes/project/ProjectCreationForm"

/*
This file is only necessary, since the backend API allows only a limited set of
project properties, when creating a project.
If the backend allows all API properties, this file is no longer necessary
and the usual general saga may be used for project creation.
*/

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

export enum ProjectActionTypes {
  // don't use "CREATE_PROJECT", it collides with the general data actions -> reduxTypes.ts (ProjectActionTypes)
  CreateNewProjectAndUpdateAfterwards = "CREATE_NEW_PROJECT_+_UPDATE_AFTERWARDS",
}

/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

interface IProjectAction extends Action {
  type: ProjectActionTypes
}

/**
 * This action triggers the creation + update of a project.
 * It is necessary, b/c the backend API allows the creation of a project only for
 * a limited range of properties, so an update after creation must be run in the SAGA.
 */
export interface ICreateExtendedProjectAction extends IProjectAction {
  actions: IFormikActions
  projectCreationData: IProjectCreationFormData
  type: ProjectActionTypes.CreateNewProjectAndUpdateAfterwards
}

/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */

/**
 * Helper function to create a project and update it afterwards to
 * handle the situation, that the backend API only allows a limited set of properties
 * when creating an Project.
 */
export const createProjectAction = (ProjectCreationData: IProjectCreationFormData, actions: IFormikActions): ICreateExtendedProjectAction => ({
  actions,
  projectCreationData: ProjectCreationData,
  type: ProjectActionTypes.CreateNewProjectAndUpdateAfterwards,
})
