import apiClient from "@api/client"
import { IAuthReply, INativeHydraCollection } from "@api/schema"
import { Routes } from "@basics/routes"
import { UNKNOWN_REQUEST_ERROR } from "@redux/common/constants"
import { showErrorsInTestEnvironment } from "@redux/common/sagaErrorHelpers"
import { usecaseRequestRunningAction, usecaseRequestSuccessAction } from "@redux/common/scopedRequest/actions"
import { selectOIDCRequestState } from "@redux/modules-adapter/oidc"
import { selectOIDCState } from "@redux/reducer"
import { setStorageItem, getStorageItem, removeStorageItem } from "@services/localStorage"
import { getStringFromQuery } from "@services/routesHelper"
import { BASE_URL, OIDC_BASIC_AUTH } from "config"

import { importantMessagesConsoleLogger, mergeRequestStates } from "./frontend-definitions/src"
import { initializeOIDCModule, IOIDCProvider } from "./oidc/src"
import { OIDCRequestScopes } from "./oidc/src/models/request-states"

// This file is a "connector" of the main application to modules.
// It helps managing dependencies: is serves as a means to prevent direct imports from "app code" into modules.
// See doc/principles/module-oriented-development.md for more details.
// 1. Modules must define what kind of APIs they need (that gain access to host application stuff).
// 2. Those definitions are used to initialize the module by using the corresponding functions from the host application.
// 3. All definitions of all modules are activated when being initialized by `initializeModules()`.

/**
 * Initialize the OICD module with required configuration data.
 * We inject required services and APIs, so that the module itself has minimal outbound dependencies.
 */
const initOIDC = () => initializeOIDCModule<IAuthReply, INativeHydraCollection<IOIDCProvider>>({
  oidcAPI: apiClient,
  requestStateAPI: {
    taskStartedAction: (scope: OIDCRequestScopes) => usecaseRequestRunningAction(scope),
    taskSucceededAction: (scope: OIDCRequestScopes) => usecaseRequestSuccessAction(scope, true),
    taskFailedAction: (scope: OIDCRequestScopes, error: string) => usecaseRequestRunningAction(scope, error),
    mergeRequestStates,
    selectRequestState: selectOIDCRequestState
  },
  selectOIDCState,
  storageAPI: {
    setStorageItem,
    getStorageItem,
    removeStorageItem
  },
  loggerAPI: importantMessagesConsoleLogger, // log.getLogger("OIDC"),
  redirectURI: BASE_URL + Routes.OAuthVerification,
  basicAuth: OIDC_BASIC_AUTH,
  getStringFromQuery,
  routeTo: (url: string) => { window.location.href = url },
  handleSagaError: showErrorsInTestEnvironment,
  getUnknownErrorCode: () => UNKNOWN_REQUEST_ERROR
})

/**
 * Initializes all required modules of our application.
 */
export const initializeModules = (): void => {
  initOIDC()
}