import { IUserObjectRole } from "@api/schema"
import { idFromIModelOrIRI } from "@basics/util-importless"
import { selectCollection } from "@redux/common/scopedObject/selectors"
import { AppState } from "@redux/reducer"
import { selectCurrentUserId } from "@redux/reducer/auth"
import { EntityType } from "@redux/common/reduxTypes"

/**
 * Selects the UserObjectRoles from the user with the given userId from the state
 *
 * @param state the current AppState
 * @param userId the id of the user or null/undefined if currentUser should be used
 * @returns an array of UserObjectRoles
 */
export const selectUserObjectRoles = (state: AppState, userId?: number): IUserObjectRole[] => {
  if (!userId) {
    userId = selectCurrentUserId(state)
  }

  return selectCollection<IUserObjectRole>(state, EntityType.UserObjectRole)
    .filter(objectRole => idFromIModelOrIRI(objectRole.user) === userId)
}