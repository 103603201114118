import { IProgram } from "@api/schema"
import { idFromIModelOrIRI } from "@basics/util-importless"


export class ProgramHelper {

  /* ************************************************************************** */
  /* constants                                                                  */
  /* ************************************************************************** */

  public static hashIdContactPageBugReport = "bug-report"

  /**
   * Checks if a given program belongs to a given tenant.
   *
   * NOTE: make sure, the program is not a nested object, b/c that does not contain the tenant property!
   * ...until https://futureprojects.atlassian.net/browse/FCP-1760 is resolved.
   *
   * @param tenantId ID of a tenant
   * @param program program that should be checked for tenant connection
   * @returns true, if the program is connected to the given tenant; false otherwise
   */
  static programHasTenant(tenantId: number, program: IProgram): boolean {
    if (!tenantId || !program) {
      return false
    }

    if (idFromIModelOrIRI(program.provider) === tenantId) {
      return true
    }

    return false
  }
}