import { IHydraCollection, INumericIdentifierModel } from "@api/schema"
import { IRequestState } from "@modules/frontend-definitions/src"
import {
  initialRequestState,
  REQUEST_STATE_SUCCESSFUL
} from "@modules/frontend-definitions/src"
import { ActionTypes, EntityType } from "@redux/common/reduxTypes"
import { IModelCLUDOperationSuccessAction } from "@redux/common/scopedObject/actions"

import {
  INewUsecaseRequestRunningAction,
  INewUsecaseRequestSuccessAction,
  NewFilteredCollectionUsecaseRequestSuccessAction,
} from "./actions"


/**
 * State to handle states for different entity collections,
 * that are loaded, updated, deleted and so on
 */
export interface IFilteredCollectionState extends IRequestState { // export for type precision casting
  /** IDs of the items that are handled by that state */
  itemIds: number[]
  /** number of totalItems: delivered from the API. Useful to see, how many items are not already fetched. */
  totalItems: number
  /** Link to fetch the next page, constructed by the API from the first-page-filtercriteria. */
  nextLink: string
  /** has (at least) the first page been loaded? */
  loaded: boolean
}

export const emptyFilteredCollectionState: IFilteredCollectionState = {
  isLoading: false,
  loadingError: null,
  itemIds: [],
  totalItems: 0,
  nextLink: null,
  loaded: false, // not loaded yet!
}

interface IFilteredCollectionPerUsecaseState {
  [usecaseKey: string]: IFilteredCollectionState
}

interface ISingleEntityPerUsecaseState {
  [usecaseKey: string]: IRequestState
}

// NOTE "export" only for tests/redux/helper/reducers.spec.ts initial state creation
export interface IEntityUsecaseState {
  singleEntities: ISingleEntityPerUsecaseState
  filteredCollections: IFilteredCollectionPerUsecaseState
}

// NOTE "export" only for tests/pages/MyProjectsDashboard.spec.tsx initial state creation
export type IEntityUsecaseStates = {
  [key in EntityType as string]: IEntityUsecaseState
}

export const emptyEntityTypeState: IEntityUsecaseState = {
  singleEntities: {},
  filteredCollections: {}
}

/**
 * empty IEntityUsecaseStates for initial states
 * NOTE "export" only for tests/pages/MyProjectsDashboard.spec.tsx initial state creation
 */
export const emptyEntityTypeStates: IEntityUsecaseStates = {}

/**
 * This reducer manages the complex entity-aware usecase-specific request states.
 * The state has the following structure:
 * [entityType]: {
 * ..[usecaseKey]: singleEntities state (array-of: CLUD of a single entity model object)
 * ..[usecaseKey]: filteredCollectionLoading state (array-of: load of a filtered collection)
 * }
 *
 * @param entityUsecaseStates the IEntityUsecaseStates object, as in AppState.entityUsecases
 * @param action an action after an api call
 * @returns a state
 *
 * // TODO rename: highlight, that this reducer works on REQUESTstates: name part "usecase" may be obsolet.
 * // e.g. entityRequest(State)Reducer to connect it to the IRequestState interface, that is used all over.
 * // Also rename connected interfaces/params/constants/actions.
 */
export const entityUsecaseReducer = (
  entityUsecaseStates: IEntityUsecaseStates = emptyEntityTypeStates,
  action: INewUsecaseRequestSuccessAction<INumericIdentifierModel | IHydraCollection<INumericIdentifierModel>>
    | INewUsecaseRequestRunningAction
    | IModelCLUDOperationSuccessAction<INumericIdentifierModel>
): IEntityUsecaseStates => {

  switch (action.type) {

    /// /////////////////
    // first block: care for all REQUEST (running or success) signals that may affect some of IEntityUsecaseState's sub-states

    // on UsecaseRequestRunning we just store the isLoading and maybe an loading error
    case ActionTypes.NewUsecaseRequestRunning:
      const usecaseRequestRunningAction = action
      const entityTypeForRunning = usecaseRequestRunningAction.entityType

      if (usecaseRequestRunningAction.isCollection) {
        // we got a Collection "request running" signal

        return {
          ...entityUsecaseStates,
          [entityTypeForRunning]: {
            ...emptyEntityTypeState,
            ...entityUsecaseStates[entityTypeForRunning],
            filteredCollections: {
              ...entityUsecaseStates[entityTypeForRunning]?.filteredCollections,
              [usecaseRequestRunningAction.usecaseKey]: {
                ...emptyFilteredCollectionState, // use empty state to initialize with default values, will be overwritten by next lines
                // @todo: check this line: https://futureprojects.atlassian.net/browse/FCP-1304
                ...entityUsecaseStates[entityTypeForRunning]?.filteredCollections?.[usecaseRequestRunningAction.usecaseKey],
                isLoading: !usecaseRequestRunningAction.error,
                loadingError: usecaseRequestRunningAction.error,
                loaded: false,
              } as IFilteredCollectionState
            }
          } as IEntityUsecaseState
        } as IEntityUsecaseStates
      } else {
        // we got a SingleModel/Entity "request running" signal
        return {
          ...entityUsecaseStates,
          [entityTypeForRunning]: {
            ...emptyEntityTypeState,
            ...entityUsecaseStates[entityTypeForRunning],
            singleEntities: {
              ...entityUsecaseStates[entityTypeForRunning]?.singleEntities,
              [usecaseRequestRunningAction.usecaseKey]: {
                ...initialRequestState, // use empty state to initialize with default values, will be overwritten by next lines
                // @todo: check this line: https://futureprojects.atlassian.net/browse/FCP-1304
                ...entityUsecaseStates[entityTypeForRunning]?.singleEntities?.[usecaseRequestRunningAction.usecaseKey],
                isLoading: !usecaseRequestRunningAction.error,
                loadingError: usecaseRequestRunningAction.error,
                loaded: false,
              } // as ISingleEntityState
            }
          } as IEntityUsecaseState
        } as IEntityUsecaseStates
      }

    // on UsecaseRequestSuccess we update the isLoading and error with null/empty, and care for loaded states,
    // and in case of Collections, we store our itemIds list.
    case ActionTypes.NewUsecaseRequestSuccess:
      const usecaseRequestSuccessAction = action
      const entityTypeForSuccess = usecaseRequestSuccessAction.entityType

      if (usecaseRequestSuccessAction.isCollection) {
        // we got a Collection "request success" signal

        const filteredCollectionUsecaseRequestSuccessAction = usecaseRequestSuccessAction as NewFilteredCollectionUsecaseRequestSuccessAction<INumericIdentifierModel>

        const items = filteredCollectionUsecaseRequestSuccessAction.result
        const loadedItemIds = items.member.map((f) => f.id)
        // next line for convenience
        const oldStateItemIds = entityUsecaseStates[entityTypeForSuccess]?.filteredCollections?.[usecaseRequestSuccessAction.usecaseKey]?.itemIds

        const mergedItemIds = filteredCollectionUsecaseRequestSuccessAction.isPage
          ? oldStateItemIds?.concat(loadedItemIds.filter((item) => oldStateItemIds.indexOf(item) < 0)) // concenate both arrays and filter out duplicates
          : loadedItemIds

        return {
          ...entityUsecaseStates,
          [entityTypeForSuccess]: {
            ...entityUsecaseStates[entityTypeForSuccess],
            filteredCollections: {
              ...entityUsecaseStates[entityTypeForSuccess]?.filteredCollections,
              [usecaseRequestSuccessAction.usecaseKey]: {
                ...emptyFilteredCollectionState, // use empty state to initialize with default values, will be overwritten by next lines
                // @todo: check this line: https://futureprojects.atlassian.net/browse/FCP-1304
                ...entityUsecaseStates[entityTypeForSuccess]?.filteredCollections?.[usecaseRequestSuccessAction.usecaseKey],
                itemIds: mergedItemIds,
                totalItems: items.totalItems,
                nextLink: items.view && items.view.next,
                ...REQUEST_STATE_SUCCESSFUL,
              } as IFilteredCollectionState
            }
          } as IEntityUsecaseState
        } as IEntityUsecaseStates
      } else {
        // we got a SingleModel/Entity "request success" signal

        return {
          ...entityUsecaseStates,
          [entityTypeForSuccess]: {
            ...entityUsecaseStates[entityTypeForSuccess],
            singleEntities: {
              ...entityUsecaseStates[entityTypeForSuccess]?.singleEntities,
              [usecaseRequestSuccessAction.usecaseKey]: {
                ...initialRequestState, // use empty state to initialize with default values, will be overwritten by next lines
                // @todo: check this line: https://futureprojects.atlassian.net/browse/FCP-1304
                ...entityUsecaseStates[entityTypeForSuccess]?.singleEntities?.[usecaseRequestSuccessAction.usecaseKey],
                ...REQUEST_STATE_SUCCESSFUL,
              } // as ISingleEntityState
            }
          } as IEntityUsecaseState
        } as IEntityUsecaseStates
      }

    /// /////////////////
    // second block: care for all CUD ModelOperationSuccess signals that may affect the IEntityUsecaseState's sub-states "filteredCollection"
    // NOTE unlike in the first block, we do NOT have to make sure that our usecase entry exists
    // All ideas to split this reducer into two reducers are obsolete,
    // b/c it is not compatible with the usage of the reducer (in the mapReducers),
    // b/c the type of the state is derived from the return type of the reducer
    // and there is only ONE slot for all RequestStates.

    // On CREATE and UPDATE model operation SUCCESS signals,
    // we clear ALL filteredCollections of the usecases of this entity.
    // All hooks that are listening to any of those collection requests
    // will re-dispatch a LoadCollectionAction, to update its loaded data.
    case ActionTypes.CreateSuccess:
    case ActionTypes.UpdateSuccess:
      const modelCLUDOperationSuccessAction = action

      const emptyUsecasesStates = {} as IFilteredCollectionPerUsecaseState
      // eslint-disable-next-line guard-for-in
      for (const usecaseKey in entityUsecaseStates[modelCLUDOperationSuccessAction.entityType]?.filteredCollections) {
        emptyUsecasesStates[usecaseKey] = emptyFilteredCollectionState
      }

      return {
        ...entityUsecaseStates,
        [modelCLUDOperationSuccessAction.entityType]: {
          ...entityUsecaseStates[modelCLUDOperationSuccessAction.entityType],
          filteredCollections: emptyUsecasesStates // clearing: replace by empty state
        }
      }
    // on DELETE model operation SUCCESS signals, we'll remove this model.id from ALL filteredCollections of this entity
    case ActionTypes.DeleteSuccess:
      const modelDeleteOperationSuccessAction = action

      const reducedUsecasesStates = {} as IFilteredCollectionPerUsecaseState
      // eslint-disable-next-line guard-for-in
      for (const usecaseKey in entityUsecaseStates[modelDeleteOperationSuccessAction.entityType]?.filteredCollections) {
        // next line for convenience
        const usecaseCollectionForDeleteState = entityUsecaseStates[modelDeleteOperationSuccessAction.entityType].filteredCollections[usecaseKey]

        const deletedModel = modelDeleteOperationSuccessAction.model
        const contains = usecaseCollectionForDeleteState.itemIds.includes(deletedModel.id)

        reducedUsecasesStates[usecaseKey] = contains
          ? {
            ...usecaseCollectionForDeleteState,
            itemIds: usecaseCollectionForDeleteState.itemIds.filter(id => id !== deletedModel.id),
            totalItems: usecaseCollectionForDeleteState.totalItems - 1,
          }
          : usecaseCollectionForDeleteState
      }

      return {
        ...entityUsecaseStates,
        [modelDeleteOperationSuccessAction.entityType]: {
          ...entityUsecaseStates[modelDeleteOperationSuccessAction.entityType],
          filteredCollections: reducedUsecasesStates,
        }
      }

  }

  return entityUsecaseStates
}
