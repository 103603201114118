import { FormikErrors } from "formik"
import { takeLatest, select, put, call, all } from "redux-saga/effects"
import { putWait } from "redux-saga-callback"

import { iriFromIModelOrIRI } from "@api/entityTypeEndpointDefinitions"
import { IProjectFollowership, IModel, IUser, IUserObjectRole, IIdea } from "@api/schema"
import { IProjectMemberApplication } from "@api/schema/action-requests"
import { Routes } from "@basics/routes"
import { idFromIModelOrIRI } from "@basics/util-importless"
import { IProjectCreationFormData } from "@components/entityTypes/project/ProjectCreationForm"
import { addNotificationAction, IAddNotificationAction } from "@redux/actions/notifications"
import { createProjectAction } from "@redux/actions/project"
import { UNKNOWN_REQUEST_ERROR } from "@redux/common/constants"
import { EntityType } from "@redux/common/reduxTypes"
import { showErrorsInTestEnvironment } from "@redux/common/sagaErrorHelpers"
import { createModelAction } from "@redux/common/scopedObject/actions"
import { getCurrentUser } from "@redux/saga/currentUser"
import { selectUserObjectRoles } from "@redux/selector/userObjectRoles"
import { IProcessOnboardingDataAfterLoginAction } from "@redux/usecases/userAccount/actions"
import { UserAccountUsecases } from "@redux/usecases/userAccount/definitions"
import { prefixedKey } from "@services/i18n"
import { routeWithParams } from "@services/routesHelper"
import { SubmissionError } from "@services/submissionError"
import { getUserObjectRoleByObjectIRI } from "@services/userObjectRolesHelper"

import { resetOnboardingStateAction } from "./actions"
import { OnboardingType } from "./definitions"
import { selectOnboardingType, selectOnboardingTypeNewIdea, selectOnboardingTypeNewProjectFollowership, selectOnboardingTypeNewProjectFromIdea as selectOnboardingTypeNewProject, selectOnboardingTypeNewProjectMemberApplication } from "./selectors"
import { createProjectMemberApplicationAction } from "../actionRequests/definitions"

export function* onboardingWatcherSaga(): any {
  yield all([
    takeLatest(UserAccountUsecases.ProcessOnboardingDataAfterLogin, postLoginForOnboardingSaga),
  ])
}

/**
 * After a user logged in successfully, check if they previously created any object of OnboardingType,
 * if yes send the data to the API, add notifications and reset the state.
 *
 * @param action IUserLoginCompletedAction
 */
function* postLoginForOnboardingSaga(action: IProcessOnboardingDataAfterLoginAction): Generator<any, IModel, any> {

  const onboardingType: OnboardingType = yield select(selectOnboardingType)
  if (onboardingType === OnboardingType.None) {
    return
  }

  try {
    /**
     * Will contain the resulting object of the onboarding process.
     */
    let resultingObject: IModel = null

    /**
     * Will contain a specific NotificationAction to be dispatched after the process
     * of creating onboarding objects was finished.
     */
    let notificationAction: IAddNotificationAction = null

    /**
     * Errors within sub saga calls to be captured.
     */
    let errorsWhenCreatingOnboardingEntity: FormikErrors<any>

    /**
     * Helper function to be given to all sub calls when creating an entity after log in.
     * @param errors FormikErrors from the sub saga
     */
    const errorCallback = (errors: FormikErrors<any>) => {
      errorsWhenCreatingOnboardingEntity = errors
    }

    switch (onboardingType) {
      case OnboardingType.NewIdea:
        const onboardingIdea: IIdea = yield select(selectOnboardingTypeNewIdea)
        resultingObject = yield putWait(createModelAction(EntityType.Idea, onboardingIdea, { setErrors: errorCallback }))
        const freshIdea: IIdea = resultingObject as IIdea

        notificationAction =
          addNotificationAction(
            {
              messageKey: "message.project.ideaSaved",
              linkTitleKey: "goto.newProject",
              route: routeWithParams(Routes.CreateProjectFromIdea, { id: idFromIModelOrIRI(freshIdea.program), ideaId: freshIdea.id })
            },
            "success",
            { autoClose: false }
          )

        break

      case OnboardingType.NewProject:
        const onboardingProject: IProjectCreationFormData = yield select(selectOnboardingTypeNewProject)

        // we expect the called saga updates the user's UORs after creating the new project
        resultingObject = yield putWait(createProjectAction(onboardingProject, { setErrors: errorCallback }))
        notificationAction = addNotificationAction("message.project.newProjectSaved", "success")
        break

      case OnboardingType.NewProjectMemberApplication:
        const onboardingMemberApplication: IProjectMemberApplication = yield select(selectOnboardingTypeNewProjectMemberApplication)

        // since there may already exist a projectMembership between the user and the project, we must check this first
        const userForMemberApplication: IUser = yield call(getCurrentUser)
        const uors: IUserObjectRole[] = yield select(selectUserObjectRoles, userForMemberApplication.id)
        const oldProjectMembershipOftheUser = getUserObjectRoleByObjectIRI(uors, iriFromIModelOrIRI(onboardingMemberApplication.receiver))

        if (!oldProjectMembershipOftheUser) {
          // the user did not already have a membership for the project
          // TODO onboarding: check: is it really relevant, if the user already has an application?

          resultingObject = yield putWait(createProjectMemberApplicationAction(
            onboardingMemberApplication.metadata,
            iriFromIModelOrIRI(onboardingMemberApplication.relatedEntity),
            { setErrors: errorCallback }
          ))

          notificationAction = addNotificationAction("message.project.memberships.saved", "success")
        } else {
          resultingObject = oldProjectMembershipOftheUser

          notificationAction = addNotificationAction(
            prefixedKey("common",
              "message.project.memberships." +
              (oldProjectMembershipOftheUser["@type"]
                ? "applicationAlreadyExists"
                : "otherMembershipAlreadyExists"
              )
            ),
            "info",
            { autoClose: false }
          )
        }
        break

      case OnboardingType.NewProjectFollowership:
        const onboardingProjectFollowership: IProjectFollowership = yield select(selectOnboardingTypeNewProjectFollowership)

        // TODO onboarding refactor if check for already existing followership is really necessary. What happens
        // if another followership on an already existing followership should be created? Problem or not?
        // since there may already exist a followership between the user and the project, we must check this first
        const oldFollowershipOftheUser: IProjectFollowership = undefined

        if (!oldFollowershipOftheUser) {
          // creating a followership means sending empty data by a logged in user to the project as a parent
          resultingObject = yield putWait(createModelAction(
            EntityType.ProjectFollowership,
            {},
            { setErrors: errorCallback },
            iriFromIModelOrIRI(onboardingProjectFollowership.project)
          ))

          notificationAction = addNotificationAction(prefixedKey("follow-project", "saved"), "success")
        } else {
          resultingObject = oldFollowershipOftheUser
          notificationAction = addNotificationAction(prefixedKey("follow-project", "alreadyExists"), "info", { autoClose: false })
        }
        break
    }

    yield put(resetOnboardingStateAction())
    yield put(notificationAction)

    // error handling
    if (errorsWhenCreatingOnboardingEntity) {
      throw new Error(
        `Error while processing onboarding data: ${String(errorsWhenCreatingOnboardingEntity).valueOf()}`,
        { cause: errorsWhenCreatingOnboardingEntity }
      )
    } else {
      if (action.onSuccess) {
        // NOTE: this onSuccess transports the onboardingType AND the resultingObject of the onboarding process.
        // Most other onSuccess() functions only have one parameter.
        yield call(action.onSuccess, onboardingType, resultingObject)
      }
    }

    return resultingObject
  } catch (err) {

    let errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    showErrorsInTestEnvironment("postLoginForOnboardingSaga", errorMessage, action, err)

    // We need a special error handling here, since we do not have direct access to a form
    // (although it's still visible for the user on the login page).
    // We won't be using setErrors as in other sagas.

    // Therefore we firstly determine the one/single errorMessage
    if (err instanceof SubmissionError) {
      // if we got more than 1 message then use general message
      const keys = Object.keys(err.errors)
      if (keys.length === 1 && typeof err.errors[keys[0]] === "string") {
        errorMessage = err.errors[keys[0]]
      }
    }

    // We want to show a generic error message as Toast/notification.
    yield put(addNotificationAction("error:validate.general.submissionFailed", "error"))

    // Additionally, we'll call a possible onError callback with the (probably more technical) error message.
    // NOTE: this differs from IFormikAction handling in other sagas!
    if (action.onError) {
      yield call(action.onError, errorMessage)
    }

    return null
  }
}