import { Action } from "redux-saga"

import { IIdea, IProjectFollowership } from "@api/schema"
import { IProjectMemberApplication } from "@api/schema/action-requests"
import { IProjectCreationFormData } from "@components/entityTypes/project/ProjectCreationForm"

import { OnboardingUsecases } from "./definitions"
import { IOnboardingState } from "./reducers"

export interface IOnboardingStateAction extends Action {
  type: OnboardingUsecases
}

/**
 * Interface to create a new Idea while onboarding.
 */
export interface IAddNewIdeaOnboardingAction extends IOnboardingStateAction {
  idea: IIdea
  type: OnboardingUsecases.AddNewIdea
}

export const addNewIdeaOnboardingAction = (idea: IIdea): IAddNewIdeaOnboardingAction => ({
  idea,
  type: OnboardingUsecases.AddNewIdea,
})

/**
 * Interface to create a new Project while onboarding.
 */
export interface IAddNewProjectOnboardingAction extends IOnboardingStateAction {
  project: IProjectCreationFormData
  type: OnboardingUsecases.AddNewProject
}

export const addNewProjectOnboardingAction = (project: IProjectCreationFormData): IAddNewProjectOnboardingAction => ({
  project,
  type: OnboardingUsecases.AddNewProject,
})

/**
 * Interface to create a new ProjectMemberApplication while onboarding.
 */
export interface IAddNewProjectMemberApplicationOnboardingAction extends IOnboardingStateAction {
  memberApplication: IProjectMemberApplication
  type: OnboardingUsecases.AddNewProjectMemberApplication
}

export const addNewProjectMemberApplicationOnboardingAction = (memberApplication: IProjectMemberApplication): IAddNewProjectMemberApplicationOnboardingAction => ({
  memberApplication,
  type: OnboardingUsecases.AddNewProjectMemberApplication,
})

/**
 * Interface to create a new ProjectFollowership while onboarding.
 */
export interface IAddNewProjectFollowershipOnboardingAction extends IOnboardingStateAction {
  projectFollowership: IProjectFollowership
  type: OnboardingUsecases.AddNewProjectFollowership
}

export const addNewProjectFollowershipOnboardingAction = (projectFollowership: IProjectFollowership): IAddNewProjectFollowershipOnboardingAction => ({
  projectFollowership,
  type: OnboardingUsecases.AddNewProjectFollowership,
})

/**
 * Action to replace the OnboardingState data within the AppState
 */
export interface IReplaceOnboardingStateAction extends IOnboardingStateAction {
  type: OnboardingUsecases.ReplaceOnboardingData
  data: IOnboardingState
}

/**
 * Helper function to create an IReplaceOnboardingStateAction.
 */
export const replaceOnboardingStateAction = (data: IOnboardingState): IReplaceOnboardingStateAction => ({
  type: OnboardingUsecases.ReplaceOnboardingData,
  data
})

/**
 * Interface to reset the OnboardingState within the AppState to an empty initial state,
 * e.g. after processing all onboarding objects after login.
 */
export interface IResetOnboardingStateAction extends IOnboardingStateAction {
  type: OnboardingUsecases.ResetOnboardingData
}

export const resetOnboardingStateAction = (): IResetOnboardingStateAction => ({
  type: OnboardingUsecases.ResetOnboardingData,
})