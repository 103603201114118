import { get } from "lodash"

import { IProjectFollowership, IIdea } from "@api/schema"
import { IProjectMemberApplication } from "@api/schema/action-requests"
import { IProjectCreationFormData } from "@components/entityTypes/project/ProjectCreationForm"

import {
  IAddNewIdeaOnboardingAction,
  IAddNewProjectOnboardingAction,
  IAddNewProjectMemberApplicationOnboardingAction,
  IAddNewProjectFollowershipOnboardingAction,
  IOnboardingStateAction,
  IReplaceOnboardingStateAction
} from "./actions"
import { OnboardingUsecases } from "./definitions"

/**
 * State within the AppState that stores
 * onboarding data.
 */
export interface IOnboardingState {
  newIdea: IIdea
  newProject: IProjectCreationFormData
  newProjectMemberApplication: IProjectMemberApplication
  newProjectFollowership: IProjectFollowership
}

// export for tests
export const emptyOnboardingState: IOnboardingState = {
  newIdea: undefined,
  newProject: undefined,
  newProjectMemberApplication: undefined,
  newProjectFollowership: undefined,
}

/**
 * Validates a given object to check, if it is usable as IOnboardingState.
 * Therefor it may be undefined or its given structure must match the expected types
 * or may be ignored.
 *
 * @param state an object from a known or unknown source
 * @returns true, if the given object may be used as IOnboardingState
 */
export const validateOnboardingState = (state: object): boolean => {

  /**
   * Helper function to check, if a given element is undefined, of type object but not array
   * @param o any object
   * @returns true, if o is undefined or an object but no array
   */
  const isValidObject = (o: any): boolean => {
    // TODO onboarding: wäre es okay, wenn state oder seine props null wären?
    if (o === undefined) {
      return true
    }

    // the object must be of type object but not of any other type, because
    // accessing a property of a non-object may lead to errors
    if (typeof o !== "object") {
      return false
    }

    // arrays are objects, but are not valid as onboardingState
    // or as value of one of its properties
    //
    // NOTE: that may be wrong for later versions, when it may be possible to process
    // more than one object of the same type when onboarding
    if (Array.isArray(o)) {
      return false
    }

    return true
  }

  // testing the object itself
  if (!isValidObject(state)) {
    return false
  }


  // testing the known properties of the object
  /**
   * Known properties of onboardingState must be defined in the emptyOnboardingState
   * so we use this to examine all known properties, even if they change over time.
   */
  const knownOnboardingStateProperties = Object.keys(emptyOnboardingState)
  const invalidProperty = knownOnboardingStateProperties.some(propertyName => !isValidObject(get(state, propertyName)))
  if (invalidProperty) {
    return false
  }

  // situations that are okay and must not be validated here:
  // - if the object contains properties that do not match those of onboarding state:
  //   no problem, they will be ignored
  // - if the object contains properties that match those of onboarding state:
  //   - if the objects are not valid: that may be recognized, when the data is sent to the backend
  //     and the backend tries to process it -> an error message may occure and error handling
  //     may handle this problem

  return true
}

export const onboardingReducer =
  (state: IOnboardingState = emptyOnboardingState, action: IOnboardingStateAction): IOnboardingState => {
    switch (action.type) {
      case OnboardingUsecases.AddNewIdea:
        const ideaAction = action as IAddNewIdeaOnboardingAction
        return {
          ...state,
          newIdea: ideaAction.idea
        }

      case OnboardingUsecases.AddNewProject:
        const projectAction = action as IAddNewProjectOnboardingAction
        return {
          ...state,
          newProject: projectAction.project
        }

      case OnboardingUsecases.AddNewProjectMemberApplication:
        const memberApplicationAction = action as IAddNewProjectMemberApplicationOnboardingAction
        return {
          ...state,
          newProjectMemberApplication: memberApplicationAction.memberApplication
        }

      case OnboardingUsecases.AddNewProjectFollowership:
        const followershipAction = action as IAddNewProjectFollowershipOnboardingAction
        return {
          ...state,
          newProjectFollowership: followershipAction.projectFollowership
        }

      case OnboardingUsecases.ReplaceOnboardingData:
        return (action as IReplaceOnboardingStateAction).data

      case OnboardingUsecases.ResetOnboardingData:
        return { ...emptyOnboardingState }

      default:
        return state
    }
  }