import { ToastOptions } from "react-toastify"
import { Action } from "redux-saga"

import { ICredentials, IUser, IAuthReply, IModel, IPasswordResetRequest } from "@api/schema"
import { IFormikActions } from "@basics/form"
import { INotificationContentType } from "@basics/notification"
import { OnboardingType } from "@redux/usecases/onboarding/definitions"

import { UserAccountUsecases } from "./definitions"


interface IUserAccountAction extends Action {
  type: UserAccountUsecases
}

export interface ILoginAction extends IUserAccountAction {
  callbackActions: IFormikActions
  credentials: ICredentials
  type: UserAccountUsecases.Login
}

export const loginAction = (credentials: ICredentials, callbackActions: IFormikActions): ILoginAction => ({
  callbackActions,
  credentials,
  type: UserAccountUsecases.Login,
})

/** Type of a function that perform actions after a user has been loaded. */
export type UserLoadedHandler = (loggedInUser: IUser) => void

export interface IPostLoginFetchUserDataAction extends IUserAccountAction {
  authReply: IAuthReply
  userLoadedHandler: UserLoadedHandler
  type: UserAccountUsecases.PostLoginFetchUserData
}

export const postLoginFetchUserDataAction = (authReply: IAuthReply, userLoadedHandler: UserLoadedHandler): IPostLoginFetchUserDataAction => ({
  authReply,
  userLoadedHandler,
  type: UserAccountUsecases.PostLoginFetchUserData,
})

/**
 * This Action only is needed, if data is stored in the OnboardingState
 * to process this data.
 *
 * There are two situations:
 * - The login form
 * - The OIDC workflow, that does not use any Form, so there is no matching situation to use IFormikActions.
 * That's why there is no setErrors (like FormikActions would do it), but onError()
 */
export interface IProcessOnboardingDataAfterLoginAction extends IUserAccountAction {
  type: UserAccountUsecases.ProcessOnboardingDataAfterLogin
  user: IUser
  onSuccess: OnOnboardingDataAfterLoginSuccessCallback
  /**
   * Callback to handle upcoming error messages.
   */
  onError: OnOnboardingDataAfterLoginErrorCallback
}

export type OnOnboardingDataAfterLoginSuccessCallback = (onboardingType: OnboardingType, createdObject: IModel) => void
/**
 * Type of a callback function to handle an error.
 */
export type OnOnboardingDataAfterLoginErrorCallback = (error: string) => void

/**
 * Helper to create an IProcessOnboardingDataAfterLoginAction
 */
export const processOnboardingDataAfterLoginAction = (
  user: IUser,
  onSuccess: OnOnboardingDataAfterLoginSuccessCallback,
  onError: OnOnboardingDataAfterLoginErrorCallback
): IProcessOnboardingDataAfterLoginAction => ({
  type: UserAccountUsecases.ProcessOnboardingDataAfterLogin,
  user,
  onSuccess,
  onError
})


export interface ILogoutAction extends IUserAccountAction {
  message?: INotificationContentType
  options?: ToastOptions
  redirect: string
  type: UserAccountUsecases.Logout
}

export const logoutAction = (redirect: string, message?: INotificationContentType, options?: ToastOptions): ILogoutAction => ({
  message,
  options,
  redirect,
  type: UserAccountUsecases.Logout,
})

/**
 * Action to trigger a process to renew a forgotten password
 */
export interface IForgotPasswordAction extends IUserAccountAction {
  callbackActions: IFormikActions
  data: IPasswordResetRequest
  type: UserAccountUsecases.ForgotPassword
}

export const forgotPasswordAction = (data: IPasswordResetRequest, callbackActions: IFormikActions): IForgotPasswordAction => ({
  callbackActions,
  data,
  type: UserAccountUsecases.ForgotPassword,
})

export interface ILoadCurrentUserAction extends IUserAccountAction {
  type: UserAccountUsecases.LoadCurrentUser
}

export const loadCurrentUserAction = (): ILoadCurrentUserAction => ({
  type: UserAccountUsecases.LoadCurrentUser,
})