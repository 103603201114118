import { all, put, select, takeLatest } from "redux-saga/effects"
import { putWait } from "redux-saga-callback"

import { IIdea, IUser, UserRole } from "@api/schema"
import { IdeaActionTypes } from "@redux/actions/idea"
import { addNotificationAction } from "@redux/actions/notifications"
import { usecaseKeyForLoadCollection } from "@redux/common/react-hooks/useEntityCollection"
import { EntityType } from "@redux/common/reduxTypes"
import { newLoadCollectionAction } from "@redux/common/scopedObject/actions"
import { IFilterCriteria } from "@redux/common/types"
import { selectCurrentUser } from "@redux/reducer/auth"
import { selectMyCreatedIdeas } from "@redux/reducer/myProjects"

export function* ideaWatcherSaga(): any {
  yield all([
    takeLatest(IdeaActionTypes.TriggerNotificationForUnusedIdea, unusedIdeaNotificationSaga),
  ])
}

/**
 * After the user logged in: check if the user has an idea without resulting
 * projects, if yes add a notification.
 */
function* unusedIdeaNotificationSaga() {
  const currentUser: IUser = yield select(selectCurrentUser)
  const criteria: IFilterCriteria = null
  const userRole: UserRole = null
  const userIri = currentUser["@id"]
  const loadAll = true

  const usecaseKey = usecaseKeyForLoadCollection(criteria, userRole, userIri)

  // Currently all ideas have to be loaded, b/c it is not possible to filter for resultingProjectCount
  // @todo multi low priority, but should be in the future fetched via user statistic as soon as it is available
  // @see https://futureprojects.atlassian.net/browse/FCP-1593
  yield putWait(newLoadCollectionAction(EntityType.Idea, criteria, usecaseKey, userIri, loadAll))
  const myIdeas: IIdea[] = yield select(selectMyCreatedIdeas)
  if (!myIdeas || myIdeas.length === 0) {
    return
  }

  const unusedIdeas = myIdeas.filter(i => i.resultingProjectCount === 0)
  if (unusedIdeas.length > 0) {
    yield put(addNotificationAction("message.project.createFromIdeaReminder", "info"))
  }
}