import { Action } from "redux"

import { IRI } from "@api/schema"
import { IStatistics } from "@api/schema/statistics"
import {
  ActionTypes,
  GeneralApiActionTypes,
} from "@redux/common/reduxTypes"
import { IUsecaseRequestRunningAction, IUsecaseRequestSuccessAction } from "@redux/common/scopedRequest/actions"


/**
 * An IStatisticsApiRequestInvokingAction is an Action that leads to an API request
 * to call the corresponding statistics-endpoint of the given entity.
 */
export interface IStatisticsApiRequestInvokingAction extends Action<GeneralApiActionTypes.LoadStatistics> {
  /**
   * IRI of the entity, for which statistics should be fetched
   */
  parentIri: IRI
}

/**
 * Creates an IStatisticsApiRequestInvokingAction to trigger the asynchroneous loading of statistics,
 * chosen by entity IRI.
 */
export const loadStatisticsAction =
  (parentIri: IRI): IStatisticsApiRequestInvokingAction => ({
    type: ActionTypes.LoadStatistics,
    parentIri,
  })

/* ************************************************************************** */

export type StatisticRequestActions = IUsecaseRequestRunningAction<GeneralApiActionTypes.LoadStatisticsRunning>
  | IUsecaseRequestSuccessAction<IStatistics, GeneralApiActionTypes.LoadStatisticsSuccess>

/**
 * Creates an IUsecaseRequestRunningAction that signalises if a usecase request is running or has stopped
 * Creating this action without an error means the request ist running/loading.
 * With an error, the request is cancelled / not loading.
 *
 * @param parentIri IRI of the entity, for which the statistics is fetched; used as usecaseKey of the request
 * @param error are there any errors, that should be reported, e.g. when a request has failed and the signal is: stopped running with errors
 * @returns a IUsecaseRequestRunningAction to be dispatched
 */
export const statisticsRequestRunningAction =
  (parentIri: IRI, error: string = null): IUsecaseRequestRunningAction<GeneralApiActionTypes.LoadStatisticsRunning> => ({
    error,
    usecaseKey: parentIri,
    type: ActionTypes.LoadStatisticsRunning
  })


/**
 * Creates an IUsecaseRequestRunningAction that signalises if a usecase request successful
 *
 * @param parentIri IRI of the entity, for which the statistics is fetched; used as usecaseKey of the request
 * @param result result of the request
 * @returns a IUsecaseRequestRunningAction to be dispatched
 */
export const statisticsRequestSuccessAction =
  (parentIri: IRI, result: IStatistics): IUsecaseRequestSuccessAction<IStatistics, GeneralApiActionTypes.LoadStatisticsSuccess> => ({
    append: false,
    result,
    usecaseKey: parentIri,
    type: ActionTypes.LoadStatisticsSuccess
  })
