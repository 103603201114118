import {
  IChallenge,
  IProgram,
  IProject,
  IUser,
  ProgramState
} from "@api/schema"
import { EntityType } from "@redux/common/reduxTypes"
import { generateSelectEntityBySlugOrId, selectCollection } from "@redux/common/scopedObject/selectors"
import { AppState } from "@redux/reducer"



/**
 * helper-function to select a user by its name
 */
export const selectUserByUsername = (state: AppState, username: string): IUser =>
  selectCollection<IUser>(state, EntityType.User)
    .filter((u) => u.username === username)
    .shift()

// #region special selectors for project and challenge by slugOrId (from slug component in routes)


/**
 * Select a project that is specified by either its slug or ID.
 * Used when the slugOrId is a string from an URL, where both properties are allowed.
 */
export const selectProjectBySlugOrId = generateSelectEntityBySlugOrId<IProject>(EntityType.Project)

/**
 * Select a challenge that is specified by either its slug or ID.
 * Used when the slugOrId is a string from an URL, where both properties are allowed.
 */
export const selectChallengeBySlugOrId = generateSelectEntityBySlugOrId<IChallenge>(EntityType.Challenge)


/**
 * Selector to retrieve the current active program.
 *
 * @todo multi
 * @returns IProgram, may be undefined
 */
export const selectCurrentProgram = (state: AppState): IProgram =>
  selectCollection<IProgram>(state, EntityType.Program).filter(program => program.state === ProgramState.Active).shift()

