import { Action } from "redux"

import { IContactEmail } from "@api/schema"
import { IFormikActions } from "@basics/form"

/*
This file contains all actions and needed enums and interfaces, regarding the programs.
*/

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

/**
 * Defines all possible Action-names regarding a program.
 */
export enum ProgramActionTypes {
  LoadCurrentProgram = "LOAD_CURRENT_PROGRAM",
  SendContactEmail = "SEND_CONTACT_EMAIL"
}


/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

/**
 * Interface to combine all Actions
 */
export type ProgramActions =
  | ILoadCurrentProgramAction
  | ISendContactEmailAction/* | IUploadProcessLogoAction */


export interface IProgramAction extends Action {
  type: ProgramActionTypes
}

/**
 * Action to trigger the (re)load of the current program.
 */
export interface ILoadCurrentProgramAction extends IProgramAction {
  type: ProgramActionTypes.LoadCurrentProgram
}

/**
 * Action to trigger an email to users of a program.
 */
export interface ISendContactEmailAction extends IProgramAction {
  actions: IFormikActions
  contactEmail: IContactEmail
  type: ProgramActionTypes.SendContactEmail
}


/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */

/**
 * Triggers the loading of a program collection and returns the first program that was found
 * after it was loaded as single entity to have detailResult.
 *
 * @todo multi: Bestimmung des "current" programs muss immer über die ID in der URL passieren
 * https://futureprojects.atlassian.net/browse/FCP-1579
 */
export const loadCurrentProgramAction = (): ILoadCurrentProgramAction => ({
  type: ProgramActionTypes.LoadCurrentProgram,
})

/**
 * Function to create an Action to trigger an email to users of a program
 */
export const sendContactEmailAction = (contactEmail: IContactEmail, actions: IFormikActions): ISendContactEmailAction => ({
  actions,
  contactEmail,
  type: ProgramActionTypes.SendContactEmail
})