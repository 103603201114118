
import { all, call, put, takeEvery } from "redux-saga/effects"

import client from "@api/client"
import { iriFromIModelOrIRI } from "@api/entityTypeEndpointDefinitions"
import { addNotificationAction } from "@redux/actions/notifications"
import { UNKNOWN_REQUEST_ERROR } from "@redux/common/constants"
import { newSingleEntityUsecaseRequestRunningAction, newSingleEntityUsecaseRequestSuccessAction } from "@redux/common/entityRequest/actions"
import { usecaseKeyForLoadCollection } from "@redux/common/react-hooks/useEntityCollection"
import { EntityType } from "@redux/common/reduxTypes"
import { showErrorsInTestEnvironment } from "@redux/common/sagaErrorHelpers"
import { newLoadCollectionAction } from "@redux/common/scopedObject/actions"
import { prefixedKey } from "@services/i18n"

import { DocumentUsecase, ICreateDocumentPdfAction, IMoveChapterAction } from "./definitions"

export function* documentWatchersSaga(): any {
  yield all([
    takeEvery(DocumentUsecase.MoveChapter, moveChapterSaga),
    takeEvery(DocumentUsecase.CreatePDF, createDocumentPdfSaga)
  ])
}

/**
 * Saga for moving a chapter within the document.
 *
 * NOTE: The saga does not refresh/reload the chapters, b/c the page/component, which triggers
 * the action for reloading the chapters does not know the usecaseKey for reloading the chapters.
 */
function* moveChapterSaga(action: IMoveChapterAction): Generator<any, void, any> {
  const usecaseKey = usecaseKeyForLoadCollection(null, null, iriFromIModelOrIRI(action.chapterToBeMoved.document))

  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Chapter, usecaseKey))

    // Returns response code 204 but no content on success.
    yield call(client.moveChapter, action.chapterToBeMoved, action.subsequentChapter)

    // Reloading the chapters, because the sortOrder has changed
    yield put(newLoadCollectionAction(
      EntityType.Chapter,
      null,
      usecaseKey,
      iriFromIModelOrIRI(action.chapterToBeMoved.document),
      true /* loadAll */
    ))

    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.Chapter, usecaseKey, null))
  } catch (err) {

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    showErrorsInTestEnvironment("moveChapterSaga", errorMessage, action, err)

    // signalize that this use case has an error
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Chapter, usecaseKey, errorMessage))

    return null
  }
}

/**
 * saga to trigger the creation of an document-pdf
 */
function* createDocumentPdfSaga(action: ICreateDocumentPdfAction): Generator<any, void, any> {
  try {
    yield call(client.createDocumentPdf, action.document)
    yield put(addNotificationAction(prefixedKey("default", "pdfFile.pdfCreationTriggered"), "success"))
  } catch (err) {
    if (err instanceof Error) {
      yield put(addNotificationAction(err.message, "error"))
      const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
      showErrorsInTestEnvironment("createDocumentPdfSaga", errorMessage, action, err)
    } else {
      // @todo
    }
  }
}