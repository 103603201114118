import { Action } from "redux"

import { IProgram, IUser, IUserEmail as IUserEmail } from "@api/schema"
import { IFormikActions } from "@basics/form"

/*
Actions for the management of Users for the (community)manager.
*/

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

export enum UserManagementActionTypes {
  NewUserPassword = "NEW_USER_PASSWORD",
  SendEmailToUsers = "SEND_EMAIL_TO_USERS"
}


/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

export interface IUserManagementAction extends Action {
  type: UserManagementActionTypes
}

export interface INewUserPasswordAction extends IUserManagementAction {
  actions: IFormikActions
  type: UserManagementActionTypes.NewUserPassword
  user: IUser
}

export interface ISendEmailToUsersAction extends IUserManagementAction {
  actions: IFormikActions
  userEmail: IUserEmail
  currentProgram: IProgram
  type: UserManagementActionTypes.SendEmailToUsers
}

export type UserManagementActions =
  // IClearUserListAction
  // |
  | INewUserPasswordAction
  | ISendEmailToUsersAction


/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */


export const newUserPasswordAction = (user: IUser, actions: IFormikActions): INewUserPasswordAction => ({
  actions,
  type: UserManagementActionTypes.NewUserPassword,
  user
})

export const sendEmailToUsersAction = (userEmail: IUserEmail, currentProgram: IProgram, actions: IFormikActions): ISendEmailToUsersAction => ({
  actions,
  userEmail,
  currentProgram,
  type: UserManagementActionTypes.SendEmailToUsers
})
