import { IRI } from "@api/schema"
import { IStatistics } from "@api/schema/statistics"
import { IRequestState, REQUEST_STATE_SUCCESSFUL } from "@modules/frontend-definitions/src"
import { ActionTypes } from "@redux/common/reduxTypes"

import {
  StatisticRequestActions
} from "./actions"


/**
 * State structure of a statistics request.
 * Contains statistics data as well as the IRequestState.
 */
export interface IStatisticsState<T extends IStatistics = IStatistics> extends IRequestState {
  // we cannot "merge" the data directly into IRequestState by extending the state with IStatistics
  // to access the properties directly, because there may be property name conflicts,
  // if a statistic property is named the same like an IRequestState property
  /** statistics data */
  data: T
}

/**
 * Request States for Statistics
 */
export type IStatisticsStates = {
  [key: IRI]: IStatisticsState
}

/**
 * empty IStatisticsStates for initial states
 * NOTE "export" only for TestState class
 *
 * // TODO multi https://futureprojects.atlassian.net/browse/FCP-1703
 */
export const emptyStatisticsStates: IStatisticsStates = {}

/**
 * This reducer manages the statistics states
 * The state has the following structure:
 * [statisticsType]: IStatistics
 *
 * @param statisticsStates the IStatisticsStates object, as in AppState.statistics
 * @param action an INewUsecaseRequestSuccessAction after an api call
 * @returns a state
 */
export const statisticsReducer = (statisticsStates: IStatisticsStates = emptyStatisticsStates, action: StatisticRequestActions): IStatisticsStates => {

  switch (action.type) {
    // on LoadStatisticsRequestRunning the request state is stored
    case ActionTypes.LoadStatisticsRunning:
      const requestAction = action
      return {
        ...statisticsStates,
        [requestAction.usecaseKey]: {
          // update request data
          isLoading: !requestAction.error,
          loadingError: requestAction.error,
          loaded: false,
          // reset recent statistics data
          data: null,
        } as IStatisticsState<IStatistics>
      }
    // on LoadStatisticsSuccess the incoming results are stored
    // and the request is marked as finished and successful
    case ActionTypes.LoadStatisticsSuccess:
      const successAction = action
      return {
        ...statisticsStates,
        [successAction.usecaseKey]: {
          // on success we expect: loading is finished, no error occured, data has been loaded
          ...REQUEST_STATE_SUCCESSFUL,
          // add received data
          data: successAction.result
        } as IStatisticsState<IStatistics>
      }
  }

  return statisticsStates
}