import { isEmptyNullOrUndefinedObject } from "@basics/util-importless"
import { EntityType } from "@redux/common/reduxTypes"

import { AbstractProjectUserRelation, INumericIdentifierModel, ISupportRequest, IRI, IUser, IMotivationAndSkills, IModel, TenantTransition, ProgramTransition } from "./schema"

/**
 * This file contains data structures that are used to send data to the API ("write DTOs").
 *
 * Some API create/update calls expect specialized structures, that differ from "normal" entity definitions.
 * Those are defined in here, including a transformation method.
 *
 * Everything is still a sketch (as of 2023-10-06) and may be repaced by a more sophisticated OOP approach.
 * See also doc/principles/data-schema.md
 *
 * NOTE: be aware, that properties may be optional when sent to the API. So check if they are given, before writing them
 * into the DTO.
 */


/**
 * IUserWriteDTO extends IUser and redefines some fields according to doc/principles/data-schema.md.
 * The IUser attributes are initialized from information the user enters in the "registration" form
 * as well as onboarding elements he created before registration (cf doc/usecases/onboarding.md).
 * We use a special API endpoint to handle that.
 */
export interface IUserWriteDTO extends IUser {
  /**
   * metadata contains any data that should be stored by the backend while the registration process
   * of a user was not finished yet, to handle this data when it is provided by the backend
   * with the verification confirmation when the user verifies his new account.
   *
   * It is especially used for "onboarding" data.
   */
  metadata: any
  verificationUrl: string
}

/**
 * An IProjectFollowershipWriteDTO represents a followership connection between a user and a project.
 * NOTE since the API requires strings on CREATE actions, we apply doc/principles/data-schema.md "Receiving objects, sending IRIs"
 */
export interface IProjectFollowershipWriteDTO extends AbstractProjectUserRelation {
  project: IRI
}


/**
 * An ISupportRequestWriteDTO represents a SupportRequest for a project.
 * NOTE since the API requires strings on CREATE actions, we apply doc/principles/data-schema.md "Receiving objects, sending IRIs"
 */
export interface ISupportRequestWriteDTO extends Omit<ISupportRequest, "project"> {
  project?: string
}

/**
 * This dto is used, when a user wants to create a project.
 * It is defined to make clear: when creating a project only a limited set of data is available.
 * More data must be written by updating a prior created project.
 * To create a project a different Action is needed:
 * @see createProjectAction()
 *
 * Note: Either an inspiration or name or shortDescription is required.
 */
export interface IProjectCreationDTO extends Required<IMotivationAndSkills>, IModel {
  /** the project will be based on this idea */
  inspirationIdea?: IRI
  /** the project will be based on this project */
  inspirationProject?: IRI
  /** the name of the project */
  name?: string
  /** program to which the project belongs */
  program: IRI
  /** applied rateplan for the project */
  ratePlan?: IRI
  /** a short description of the project */
  shortDescription?: string
}

// #region transition

/**
 * Data structure defined by the backend to perform a transition.
 * It is send to the backend.
 *
 * The "Transition" is the step that should be performed, to step from one (entity) state to another.
 *
 * @template TransitionEnum transitions that may be performed
 */
export type TransitionDTO<TransitionEnum> = {
  /**
   * Action to be performed to step from one state to another.
   * Accepted values are defined in the backend, especially in the config/packages/workflow.yaml
   */
  action: TransitionEnum
  message?: string
}

/**
 * StateChangeInput inherits in the backend from AbstractTransitionInput
 * so we inherit the IStateChangeDTO from the TransitionDTO.
 */
export interface IStateChangeDTO extends TransitionDTO<string> {
  internalNote?: string
}


/* @todo multi add other transitions: challenge, fund, proposal, fundapplication, program @see https://futureprojects.atlassian.net/browse/FCP-1553 */
/**
 * pre-defined DTO for transitioning an ITenant to be ended
 */
export const EndTenantTransitionInput: Readonly<TransitionDTO<TenantTransition>> = { "action": TenantTransition.End }

/**
 * pre-defined DTO for transitioning an ITenant to be activated
 */
export const ActivateTenantTransitionInput: Readonly<TransitionDTO<TenantTransition>> = { "action": TenantTransition.Activate }

/**
 * pre-defined DTO for transitioning an IProgram to be activated
 */
export const ActivateProgramTransitionInput: Readonly<TransitionDTO<ProgramTransition>> = { "action": ProgramTransition.Activate }

/**
 * pre-defined DTO for transitioning an IProgram to be ended
 */
export const EndProgramTransitionInput: Readonly<TransitionDTO<ProgramTransition>> = { "action": ProgramTransition.End }

// #endregion transition

/**
 * Prepare entities (or other schema objects) before calling the API's create method resp. before
 * POSTing entities/objects to the API.
 * If transformation is necessary, it returns a WriteDTO, as defined above, that mostly matches
 * the EntityType (or interface type) of the given object, but may differ in certain attributes.
 *
 * This may be necessary due to following reasons:
 *
 * 1) Transforming nested documents to simple IRIs:
 * The (API-side) EntityType allows INumericIdentifierModel | string for a certain property,
 * and we want to use the model object in the client before calling create for a richer user experience,
 * but the API does not accept nested documents in create calls, instead expects IRIs.
 *
 * @param entityType the entitytype of the given entity
 * @param model the entity (or other schema object) to be prepared
 * @returns a (possibly changed) entity (or other schema object) only useful for sending POST and PATCH requests
 */
export const transformEntityToWriteDTO = <T extends INumericIdentifierModel>(entityType: EntityType, model: INumericIdentifierModel): T => {

  if (isEmptyNullOrUndefinedObject(model)) {
    return model as T
  }

  switch (entityType) {
    case EntityType.User:
      // NOTE it may or not be that `model` already has been transformed to a WriteDTO, so we must handle both.
      // We do this by adding the elements to `userWriteDTO` in a very special order.
      const user = model as IUser | IUserWriteDTO
      const userWriteDTO: IUserWriteDTO = {
        // NOTE since user may be a IUserWriteDTO and already contain a verificationUrl or metaData,
        // we must allow overwriting in the next+1 line
        verificationUrl: undefined,
        metadata: undefined,

        ...user,
      }

      return userWriteDTO as undefined as T

    case EntityType.SupportRequest:
      // NOTE it may or not be that `model` already has been transformed to a WriteDTO, so we must handle both.
      // We do this by testing the typeof
      const supportRequest = model as ISupportRequest | ISupportRequestWriteDTO
      const supportRequestWriteDTO: ISupportRequestWriteDTO = {
        ...supportRequest,
        project: typeof supportRequest.project === "string" ? supportRequest.project : supportRequest.project?.["@id"],
      }

      return supportRequestWriteDTO as undefined as T
  }

  return model as T
}