import { all, call, CallEffect, put, select, SelectEffect, takeEvery, takeLatest } from "redux-saga/effects"
import { putWait, withCallback } from "redux-saga-callback"

import apiClient from "@api/client"
import { IProgram } from "@api/schema"
import { addNotificationAction } from "@redux/actions/notifications"
import { ILoadCurrentProgramAction, ISendContactEmailAction, ProgramActionTypes, loadCurrentProgramAction } from "@redux/actions/program"
import { UNKNOWN_REQUEST_ERROR } from "@redux/common/constants"
import {
  newSingleEntityUsecaseRequestRunningAction,
  newSingleEntityUsecaseRequestSuccessAction,
} from "@redux/common/entityRequest/actions"
import { selectSingleEntityUsecaseState } from "@redux/common/entityRequest/selectors"
import { EntityType } from "@redux/common/reduxTypes"
import { showErrorsInTestEnvironment } from "@redux/common/sagaErrorHelpers"
import { loadModelSuccessAction } from "@redux/common/scopedObject/actions"
import { usecaseRequestRunningAction } from "@redux/common/scopedRequest/actions"
import { AppState } from "@redux/reducer"
import { selectCurrentProgram } from "@redux/selector/misc"
import { SubmissionError } from "@services/submissionError"


/**
 * WatcherSaga, that watches on incoming Actions and calls the corresponding saga
 */
export function* programWatcherSaga(): any {
  yield all([
    takeLatest(ProgramActionTypes.LoadCurrentProgram, withCallback(loadCurrentProgramSaga)),
    takeEvery(ProgramActionTypes.SendContactEmail, sendContactEmailSaga)
  ])
}

/**
 * Returns the current program if already in state or triggers to load it
 */
export function* getCurrentProgram(): Generator<SelectEffect | CallEffect<any>, IProgram, IProgram> {
  let currentProgram: IProgram = yield select(selectCurrentProgram)
  if (!currentProgram) {
    currentProgram = yield putWait(loadCurrentProgramAction())
  }

  return currentProgram
}


/**
 * Saga to load the current program
 *
 * @todo for rework: ScopeType umbenennen in LoadCurrentProgram um den Sonderfall klar abzugrenzen,
 * aber innerhalb der Saga die client.loadEntityCollection und die clientloadSingleEntity nutzen
 * @todo multi: darauf achten, dass es im Multimandanten mehrere programs geben kann -> wie wird festgestellt, welcher program "current" ist?
 */
function* loadCurrentProgramSaga(action: ILoadCurrentProgramAction) {
  const usecaseKey = action.type // @TODO fixme: usecaseKey may be refactored into action, see loadModelAction etc
  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Program, usecaseKey))
    const currentProgram: IProgram = yield call(apiClient.getCurrentProgram)
    if (currentProgram) {
      yield put(loadModelSuccessAction(EntityType.Program, currentProgram))
    }
    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.Program, usecaseKey, currentProgram))

    return currentProgram
  } catch (err) {

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    showErrorsInTestEnvironment("loadCurrentProgramSaga", errorMessage, action, err)

    // if an error occurred: signalize that the usecaseKey-request has failed with the error message
    yield put(usecaseRequestRunningAction(usecaseKey, errorMessage))
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Program, usecaseKey, errorMessage))
    return null
  }
}


/**
 * saga to contact the program owners
 * especially when using the general contact form
 */
function* sendContactEmailSaga(action: ISendContactEmailAction) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}
  const usecaseKey = action.type // @TODO fixme: usecaseKey may be refactored into action, see loadModelAction etc

  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Program, usecaseKey))
    const currentProgram: IProgram = yield call(getCurrentProgram)
    if (!currentProgram) {
      const err: string = yield select((s: AppState) => selectSingleEntityUsecaseState(s, EntityType.Program, ProgramActionTypes.LoadCurrentProgram)?.loadingError)
      yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Program, usecaseKey, err))
      return null
    }

    yield call(apiClient.sendContactEmail, currentProgram.id, action.contactEmail)

    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.Program, usecaseKey, null))

    yield put(addNotificationAction("message.process.contact.success", "success"))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    if (onSuccess) {
      yield call(onSuccess, currentProgram)
    }

    return true
  } catch (err) {
    if (err instanceof Error) {
      if (err instanceof SubmissionError) {
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: err.message })
      }
    }

    yield put(addNotificationAction("message.process.contact.failure", "error"))

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    showErrorsInTestEnvironment("sendContactEmailSaga", errorMessage, action, err)

    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Program, usecaseKey, errorMessage))
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return false
  }
}
