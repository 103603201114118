import { IIdea, IProjectFollowership } from "@api/schema"
import { IProjectMemberApplication } from "@api/schema/action-requests"
import { IProjectCreationFormData } from "@components/entityTypes/project/ProjectCreationForm"
import { AppState } from "@redux/reducer"

import { OnboardingType } from "./definitions"
import { IOnboardingState } from "./reducers"

/**
 * Selects the whole onboarding data branch from the AppState.
 */
export const selectOnboardingData = (state: AppState): IOnboardingState =>
  state.onboardingData

/**
 * Selects the newIdea sub branch from the onboarding data branch from the AppState.
 */
export const selectOnboardingTypeNewIdea = (s: AppState): IIdea =>
  s.onboardingData.newIdea

/**
 * Selects the newProject sub branch from the onboarding data branch from the AppState.
 */
export const selectOnboardingTypeNewProjectFromIdea = (s: AppState): IProjectCreationFormData =>
  s.onboardingData.newProject

/**
 * Selects the newProjectMembership sub branch from the onboarding data branch from the AppState.
 */
export const selectOnboardingTypeNewProjectMemberApplication = (s: AppState): IProjectMemberApplication =>
  s.onboardingData.newProjectMemberApplication

/**
 * Selects the newProjectFollowership sub branch from the onboarding data branch from the AppState.
 */
export const selectOnboardingTypeNewProjectFollowership = (s: AppState): IProjectFollowership =>
  s.onboardingData.newProjectFollowership

/**
 * Selects the first available onboarding data from the state
 * and returns its type.
 *
 * NOTE we silently ignore the case where more than 1 onboarding data is present
 */
export const selectOnboardingType = (state: AppState): OnboardingType => {
  if (state.onboardingData.newIdea) {
    return OnboardingType.NewIdea
  }
  if (state.onboardingData.newProject) {
    return OnboardingType.NewProject
  }
  if (state.onboardingData.newProjectMemberApplication) {
    return OnboardingType.NewProjectMemberApplication
  }
  if (state.onboardingData.newProjectFollowership) {
    return OnboardingType.NewProjectFollowership
  }

  return OnboardingType.None
}

/**
 * Test if there exists an onboarding data element of another type and returns the type,
 * otherwise null.
 *
 * @param state The AppState
 * @param excludedOnboardingType The OnboardingType that should be excluded
 * @returns the type of the other onboarding data element in the global state.onboardingData; otherwise null
 */
export const selectOtherExistingOnboardingType = (state: AppState, excludedOnboardingType: OnboardingType): OnboardingType => {
  const type = selectOnboardingType(state)
  if ([OnboardingType.None, excludedOnboardingType].includes(type)) {
    return null
  } else {
    return type
  }
}