import { useRouter } from "next/router"
import { DropdownItem } from "reactstrap"

import ActiveLink from "@components/common/ActiveLink"

interface IProps {
  href?: string
  /**
   * A (translated) text ready for display.
   */
  label: string
  onClick?: (...args: any) => void
}

/**
 * Provides an DropdownItem within an AuthElement if needed.
 * Either href or onClick must be provided
 * Note that `label` is a string that is already translated, and not an i18n key.
 */
const NavigationDropDownItem: React.FC<IProps> = ({ href, label = "", onClick }: IProps) => {
  const router = useRouter()

  const navigationLink = href
    ? <ActiveLink href={href} title={label} onClick={onClick}>
      {/* Brackets are necessary to avoid "Error: React.Children.only expected to receive a single React element child" */}
      <>{label}</>
    </ActiveLink>
    : <a title={label} onClick={onClick}>{label}</a>


  return <DropdownItem onKeyDown={(event) => {
    if (event.key === "Enter") {
      if (href) {
        void router.push(href)
      } else {
        if (onClick) {
          onClick()
        }
      }
    }
  }}>
    {navigationLink}
  </DropdownItem>
}

export default NavigationDropDownItem
