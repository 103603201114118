import { Action } from "redux-saga"

import { IChapter, IDocument } from "@modules/documents/src/models/documents"

export enum DocumentUsecase {
  CreatePDF = "_usecase_create_document_pdf",
  MoveChapter = "_usecase_move_chapter"
}

/**
 * API triggering Action type for moving a chapter ahead of a different chapter.
 */
export interface IMoveChapterAction extends Action<DocumentUsecase.MoveChapter> {
  /** the chapter, which needs to be moved */
  chapterToBeMoved: IChapter
  /** the destination chapter, where the `moveChapter`will be put ahead of */
  subsequentChapter: IChapter
}

/**
 * API triggering Action to move a chapter in front of a different chapter within the same document.
 *
 * @param chapterToBeMoved the chapter, which needs to be moved.
 * @param subsequentChapter the destination chapter, where the `moveChapter`will be put ahead of.
 * @returns object of type  @see IMoveChapterAction
 */
export const moveChapterAction = (chapterToBeMoved: IChapter, subsequentChapter: IChapter): IMoveChapterAction => ({
  chapterToBeMoved,
  subsequentChapter,
  type: DocumentUsecase.MoveChapter
})

/**
 * API triggering Action type to create a pdf of a document.
 */
export interface ICreateDocumentPdfAction {
  document: IDocument
  type: DocumentUsecase.CreatePDF
}

/**
 * API triggering Action to create a pdf of a document.
 */
export const createDocumentPdfAction = (document: IDocument): ICreateDocumentPdfAction => ({
  document,
  type: DocumentUsecase.CreatePDF,
})