import Link from "next/link"
import { useRouter } from "next/router"
import useTranslation from "next-translate/useTranslation"
import { useSelector } from "react-redux"
import { Col, Row } from "reactstrap"

import { Routes } from "@basics/routes"
import BaseLayout from "@components/BaseLayout"
import { AppState } from "@redux/reducer"
import { selectIsAuthenticated } from "@redux/reducer/auth"
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"
import { useLoginRouteWithRedirectBack } from "@services/routesHelper"

import HtmlContent from "./common/HtmlContent"
import { withDynamicNamespaces } from "./hoc/withDynamicNamespaces"

const usedNamespaces: NamespaceShortcut[] = ["goto"]

interface IProps {
  /**
   * Error from the api request. Will be extended by error namespace and translated
   */
  error?: string
  /**
   * Params that should be used when translating an error i18n string to user readable text.
   */
  errorTranslationParams?: Record<string, string>
  /**
   * Error code, e.g. 500, 404 etc.
   */
  statusCode?: number
  /**
   * Title of the page
   */
  title?: string
  /**
   * Extra data to make analysis more easy vor coders.
   */
  extraData?: string
}

/**
 * This component provides an error message to the user
 */
const ErrorPage: React.FC<IProps> = (props: IProps) => {
  const { error, errorTranslationParams, extraData, statusCode = 500, title = `error:title.${statusCode}` } = props
  const { t } = useTranslation("common")
  const td = useDynamicTranslation()
  const router = useRouter()

  const errorString = error ? td("error", error, errorTranslationParams) : td("error", `explanation.${statusCode}`)

  const isAuthenticated = useSelector((state: AppState) => selectIsAuthenticated(state))
  const loginWithRedirectBack = useLoginRouteWithRedirectBack()

  return <BaseLayout pageTitle={t(title)}>
    <Row>
      <Col className="text-center">
        <h1>{t(title)}</h1>
        <p>
          <HtmlContent content={errorString} />
        </p>
        {extraData && <p>{extraData}</p>}
        <div className="btn-flex-layout justify-content-center">
          <Link
            href='#'
            className="btn primary-btn"
            title={t("goto.reload")}
            onClick={router.reload}
          >
            {t("goto.reload")}
          </Link>
          {isAuthenticated
            ? <Link
              href={Routes.MyProjects}
              className="btn primary-btn"
              title={td("goto", "user.myProjects")}
            >
              {td("goto", "user.myProjects")}
            </Link>
            : <Link
              href={loginWithRedirectBack}
              className="btn primary-btn"
              title={t("goto.login")}
            >
              {t("goto.login")}
            </Link>
          }
          <Link
            href={Routes.Home}
            className="btn primary-btn"
            title={t("goto.home")}
          >
            {t("goto.home")}
          </Link>
        </div>
      </Col>
    </Row>
  </BaseLayout>
}

export default withDynamicNamespaces<IProps>(ErrorPage, usedNamespaces)
