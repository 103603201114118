import { FormikHelpers } from "formik"

import { stringAsHtmlId } from "./util-importless"

/**
 * returns an ID for fields with error
 */
export const errorIdForField = (fieldName: string): string => "error_on_field_" + stringAsHtmlId(fieldName)

/**
 * Default classname for all input fields, especially used to extract all input fields in tests.
 */
export const INPUT_FIELD_DEFAULT_CLASSNAME = "form-input"

/**
 * Default classname for all error divs for form input fields.
 */
export const INVALID_FORM_INPUT_CLASSNAME = "invalid-form-input"

/**
 * IFormikActions are used in every Formik-based form to report back successful or non-successful submissions
 */
export interface IFormikActions extends Partial<FormikHelpers<any>> {
  /**
   * Coder should define a function and assign it to onSuccess in the onSubmit-Function for a Formik-Form,
   * that should be called in the corresponding Saga, when a request was successful.
   */
  onSuccess?: (...args: any) => void
}